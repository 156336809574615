import { REQUEST_API_URL } from "../config/constant";

export async function ApiResetPasswordApply(business_email: string){
    const response: any = await fetch(REQUEST_API_URL + '/reset_password', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-store',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'error',
        referrerPolicy: 'no-referrer-when-downgrade',
        body: JSON.stringify({business_email:business_email})
    })
        
    return response.json();
}