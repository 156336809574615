import { useEffect, useState } from "react";
import { useStyles } from "./EmailChangeFormStyle";

import Layout from "layout/Layout";
import clsx from "clsx";
import ActionInput from "components/ActionInput/ActionInput";
import ActionButton2 from "components/ActionButton2/ActionButton2";
import SendIcon from "@mui/icons-material/Send";
import { ApiResetPasswordApply } from "Apis/ApiResetPasswordApply";
import { RES_STATUS_OK } from "config/constant";


export const EmailChangeForm = () => {
  const classes = useStyles();

  const [apiWaitStatus, setApiWaitStatus] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [resultMsg, setResultMsg] = useState("");
  const [personEmail, setPersonEmail] = useState("");
  const [resetPasswordDisplayStatus, setResetPasswordDisplayStatus] =
  useState(false);


  const handleResetPassword = async () => {
    if (personEmail.length == 0) {
      setErrMsg("メールアドレスが入力されていません。");
      return;
    } else {
      if (!/^[^@]+@[^@.]+.[^@]+$/.test(personEmail)) {
        setErrMsg("メールアドレスの形式が正しくありません。");
        return;
      }
    }

    setApiWaitStatus(true);

    const response = await ApiResetPasswordApply(personEmail);
    if (response["result-status"] === RES_STATUS_OK) {
      setErrMsg("");
      setResultMsg(response["message"]);
    } else {
      setResultMsg("");
      setErrMsg(response["message"]);
    }
    setApiWaitStatus(false);
  };

  return (
    <div className={classes.loaderWrapper}>
      <div className={classes.modalRoot}>
        <div className={classes.title}>パスワード再設定</div>
        <div className={classes.mainContainer}>
          <div
            className={clsx(
              classes.resetPassword,
              classes.resetPasswordDiscription
            )}
          >
            アカウントのログイン用メールアドレスをご入力ください。
            <br />
            パスワード再設定用のリンクをメールします。
          </div>
          <div className={clsx(classes.message, classes.errorMessage)}>
            {errMsg}
          </div>
          <div className={clsx(classes.message, classes.resultMessage)}>
            {resultMsg}
          </div>
          <div className={classes.form}>
            <div className={classes.formTitle}>メールアドレス</div>
            <ActionInput
              value={personEmail}
              action={(e) => setPersonEmail(e)}
              placeholder="example@jinjer-fukuri-app.jp"
              className={classes.formInput}
            />
          </div>
        </div>
        <div className={classes.btnContainer}>
          <ActionButton2
            type="dark"
            disable={apiWaitStatus}
            action={handleResetPassword}
            loading={apiWaitStatus}
            endIcon={<SendIcon />}
            content={apiWaitStatus ? "処理中" : "再設定用のリンクを送る"}
            className={clsx(
              classes.sendBtn,
              apiWaitStatus && classes.loading
            )}
          />
        </div>
      </div>
    </div>
  );
};
