import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: {
      display: "flex",
      justifyContent: "center",
    },
    loaderWrapper: {
      width: "100%",
      height: "100%",
      overflowY: "scroll",
      padding: "16px",
      justifyContent: "center",
      alignItems: "center",
      fontFamily: "Noto Sans JP",
    },
    title: {
      fontStyle: "normal",
      //fontFamily: "Montserrat",
      fontFamily: "Noto Sans JP",
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: "50px",
      color: "#55727e",
    },
    textContainer: {
      padding: "18px 50px 0 50px",
      [theme.breakpoints.down(540)]: {
        padding: 0,
      },
    },
    installContainer: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    borderLine: {
      width: "100%",
      borderTop: "2px solid #c6c6c6",
      marginTop: "10px",
    },
    installText: {
      fontSize: "16px",
      fontWeight: "bold",
      marginTop: "12px",
      marginBottom: "16px",
      color: "#878787",
    },
    nativeLink: {
      display: "flex",
      justifyContent: "center",
    },
    linkBtn: {
      margin: "0 10px",
    },
    nativeButtonImage: {
      maxHeight: "40px",
    },
  })
);
