import ActionButton from "components/ActionButton/ActionButton";
import ActionInput from "components/ActionInput/ActionInput";
import { useEffect, useState } from "react";
import { useStyles } from "./ResetPasswordStyle";
import { createLoginMonitor } from "../../Utilities/LoginManager";
import { PASSWORD_RESET_FORM_LINK, RES_STATUS_OK } from "config/constant";
import { useParams } from "react-router-dom";
import { ApiResetPassword } from "../../Apis/ApiResetPassword";
import clsx from "clsx";
import MessageModal from "components/MessageModal/MessageModal";
import { auth } from "Auth/Auth";

export const ResetPassword = () => {
    const classes = useStyles();
    const [password, setPassword] = useState("");
    const [conPassword, setConPassword] = useState("");
    const [email, setEmail] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const { token } = useParams();

    const handleNext = async () => {

        if (password.length < 8) {
            setErrMsg("パスワードは8文字以上入力してください。");
            return;
        } else {
            if (!(password.match(/([a-zA-Z])/) && password.match(/([0-9])/))) {
                setErrMsg("パスワードは英数字混合にしてください。");
                return;
            } else {
                if (password !== conPassword) {
                    setErrMsg("パスワードとパスワード（再入力）が一致しません。");
                    return;
                }
            }
        }

        const response = await ApiResetPassword(token, password)
        if (response["result-status"] === RES_STATUS_OK) {
            setEmail(response["result-object"].business_email);
            setResetPasswordFinishStatus(true);
        } else {
            setErrMsg(response["message"]);
            return;
        }
    };

    const [resetPasswordFinishStatus, setResetPasswordFinishStatus] = useState(false);

    function handleResetPasswordFinish() {
        setResetPasswordFinishStatus(false);
        window.location.replace(PASSWORD_RESET_FORM_LINK)
    }

    return (
        <div className={classes.loaderWrapper}>
            <div className={classes.modalRoot}>
                <div className={classes.title}>パスワードの再登録</div>
                <div className={classes.mainContainer}>
                    <div className={classes.discription}>
                        新しいパスワードをご入力ください。
                    </div>
                    <div className={clsx(classes.message, classes.errorMessage)}>
                        {errMsg ? errMsg.split("\n").map((item: any, key: any) => {
                            return (
                                <span key={key}>{item}<br /></span>
                            );
                        }) : ""}
                    </div>
                    <div className={classes.form}>
                        <div className={classes.formTitle}>新しいパスワード</div>
                        <ActionInput
                            value={password}
                            type='password'
                            action={(e) => setPassword(e)}
                            placeholder='********'
                            className={classes.formInput}
                        />
                    </div>
                    <div className={classes.form}>
                        <div className={classes.formTitle}>新しいパスワード（確認）</div>
                        <ActionInput
                            value={conPassword}
                            type='password'
                            action={(e) => setConPassword(e)}
                            placeholder='********'
                            className={classes.formInput}
                        />
                    </div>
                </div>
                <div className={classes.btnContainer}>
                    <ActionButton
                        content='登録'
                        type='dark'
                        className={classes.confirmBtn}
                        action={handleNext}
                    />
                </div>
            </div>
            <MessageModal
                title='パスワード再設定完了'
                description={`パスワード再設定が完了しました。`}
                show={resetPasswordFinishStatus}
                onClose={() => setResetPasswordFinishStatus(false)}
                action={handleResetPasswordFinish}
            />
        </div>
    );
};
