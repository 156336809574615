import ActionButton from "components/ActionButton/ActionButton";
import ActionInput from "components/ActionInput/ActionInput";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./LoginStyle";
import { auth } from "Auth/Auth";
import { createLoginMonitor } from "../../Utilities/LoginManager";
import ActionCheckbox from "components/ActionCheckbox/ActionCheckbox";
import { RES_STATUS_OK } from "config/constant";
import clsx from "clsx";
import { ApiResetPasswordApply } from "Apis/ApiResetPasswordApply";
import ActionButton2 from "components/ActionButton2/ActionButton2";
import SendIcon from "@mui/icons-material/Send";
import { recordAccessLog } from "Utilities/appHelper";
import Layout from "layout/Layout";

export const Login = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [personEmail, setPersonEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [resetPasswordDisplayStatus, setResetPasswordDisplayStatus] =
    useState(false);
  const loginMonitor = createLoginMonitor();

  const [apiWaitStatus, setApiWaitStatus] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [resultMsg, setResultMsg] = useState("");

  const handleNext = async () => {
    var dirs = window.location.pathname.split("/");

    const response = await auth(
      personEmail,
      password,
      rememberMe,
      loginMonitor
    );
    console.log(response);
    if (response["result-status"] === RES_STATUS_OK) {
      const userId = response["result-object"]["id"];
      const clientId = response["result-object"]["clientId"];
      localStorage.setItem("user_key", userId);
      recordAccessLog(userId, clientId);

      loginMonitor.login();
    } else {
      setErrMsg(response["message"]);
      return;
    }
    if (dirs[1] === "login") {
      window.location.replace("/");
    } else {
      window.location.reload();
    }
  };

  useEffect(() => {}, [rememberMe]);

  const handleResetPasswordDisplay = () => {
    setErrMsg("");
    setResultMsg("");
    setResetPasswordDisplayStatus(true);
  };

  const handleLoginDisplay = () => {
    setErrMsg("");
    setResultMsg("");
    setResetPasswordDisplayStatus(false);
  };

  const handleResetPassword = async () => {
    if (personEmail.length == 0) {
      setErrMsg("メールアドレスが入力されていません。");
      return;
    } else {
      if (!/^[^@]+@[^@.]+.[^@]+$/.test(personEmail)) {
        setErrMsg("メールアドレスの形式が正しくありません。");
        return;
      }
    }

    setApiWaitStatus(true);

    const response = await ApiResetPasswordApply(personEmail);
    if (response["result-status"] === RES_STATUS_OK) {
      setErrMsg("");
      setResultMsg(response["message"]);
    } else {
      setResultMsg("");
      setErrMsg(response["message"]);
    }
    setApiWaitStatus(false);
  };

  return (
    <Layout>
      <div className={classes.loaderWrapper}>
        <div className={classes.modalRoot}>
          {!resetPasswordDisplayStatus ? (
            <>
              <div className={classes.title}>ログイン</div>
              <div className={classes.mainContainer}>
                <div className={clsx(classes.message, classes.errorMessage)}>
                  {errMsg}
                </div>
                <div className={classes.form}>
                  <div className={classes.formTitle}>メールアドレス</div>
                  <ActionInput
                    value={personEmail}
                    action={(e) => setPersonEmail(e)}
                    placeholder="example@fukuri-app.jp"
                    className={classes.formInput}
                  />
                </div>
                <div className={classes.form}>
                  <div className={classes.formTitle}>パスワード</div>
                  <ActionInput
                    value={password}
                    type="password"
                    action={(e) => setPassword(e)}
                    placeholder="********"
                    className={classes.formInput}
                  />
                </div>
                <div className={classes.rememberMe}>
                  <ActionCheckbox
                    type="checkbox"
                    value="次回から自動的にログイン"
                    checked={rememberMe}
                    action={(e) => setRememberMe(e)}
                  />
                  次回から自動的にログイン
                </div>
                <div
                  className={classes.resetPassword}
                  onClick={() => handleResetPasswordDisplay()}
                >
                  パスワードをお忘れですか？
                </div>
              </div>
              <div className={classes.btnContainer}>
                <ActionButton
                  content="キャンセル"
                  className={classes.cancelBtn}
                  action={() => {
                    window.location.replace("/");
                  }}
                />
                <ActionButton
                  content="ログイン"
                  type="dark"
                  className={classes.confirmBtn}
                  action={handleNext}
                />
              </div>
            </>
          ) : (
            <>
              <div className={classes.title}>パスワード再設定</div>
              <div className={classes.mainContainer}>
                <div
                  className={clsx(
                    classes.resetPassword,
                    classes.resetPasswordDiscription
                  )}
                >
                  アカウントのログイン用メールアドレスをご入力ください。
                  <br />
                  パスワード再設定用のリンクをメールします。
                </div>
                <div className={clsx(classes.message, classes.errorMessage)}>
                  {errMsg}
                </div>
                <div className={clsx(classes.message, classes.resultMessage)}>
                  {resultMsg}
                </div>
                <div className={classes.form}>
                  <div className={classes.formTitle}>メールアドレス</div>
                  <ActionInput
                    value={personEmail}
                    action={(e) => setPersonEmail(e)}
                    placeholder="example@fukuri-app.jp"
                    className={classes.formInput}
                  />
                </div>
              </div>
              <div className={classes.btnContainer}>
                {/* <ActionButton
                content='戻る'
                className={classes.cancelBtn}
                action={() => handleLoginDisplay()}
              />
              <ActionButton
                  content='再設定用のリンクを送る'
                  type='dark'
                  className={classes.confirmBtn}
                  action={handleResetPassword}
                /> */}
                <ActionButton2
                  loading={false}
                  disable={apiWaitStatus}
                  action={() => handleLoginDisplay()}
                  content="戻る"
                  className={clsx(
                    classes.backBtn,
                    apiWaitStatus && classes.loading
                  )}
                />
                <ActionButton2
                  type="dark"
                  disable={apiWaitStatus}
                  action={handleResetPassword}
                  loading={apiWaitStatus}
                  endIcon={<SendIcon />}
                  content={apiWaitStatus ? "処理中" : "再設定用のリンクを送る"}
                  className={clsx(
                    classes.sendBtn,
                    apiWaitStatus && classes.loading
                  )}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};
